import axios from 'axios';
import qs from 'qs';

import { GetTotalMeterListFunc, CreateTotalMeterFunc, UpdateTotalMeterFunc, DeleteTotalMeterFunc } from './types';

export const getTotalMeterList: GetTotalMeterListFunc = async (context) => {
  const [, queryObj] = context.queryKey;
  const queryString = queryObj ? qs.stringify(queryObj, { arrayFormat: 'comma', addQueryPrefix: true }) : '';

  const res = await axios({
    method: 'GET',
    url: `/totalMeter${queryString}`,
  });

  return res.data;
};

export const createTotalMeter: CreateTotalMeterFunc = async (payload) => {
  const res = await axios({
    method: 'POST',
    url: '/totalMeter',
    data: payload,
  });

  return res.data;
};

export const updateTotalMeter: UpdateTotalMeterFunc = async ({ totalMeterId, ...payload }) => {
  const res = await axios({
    method: 'PATCH',
    url: `/totalMeter/${totalMeterId}`,
    data: payload,
  });

  return res.data;
};

export const deleteTotalMeter: DeleteTotalMeterFunc = async ({ totalMeterId, schoolId }) => {
  const res = await axios({
    method: 'DELETE',
    url: `/totalMeter/${totalMeterId}`,
    data: { schoolId },
  });

  return res.data;
};
