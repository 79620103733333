import { lazy } from 'react';

import { RoutesConfig } from 'routes/config';
import { RoleNumberEnum } from 'hooks/auth';
import { DeviceType } from 'hooks/device';

const SchoolList = lazy(() => import('pages/schoolList'));
const SchoolEdit = lazy(() => import('pages/schoolList/editSchool'));
const SchoolAdd = lazy(() => import('pages/schoolList/addSchool'));
const SurveyInfoEditOrAdd = lazy(() => import('pages/schoolList/editOrAddSurveyInfo'));
const RoomDevices = lazy(() => import('pages/schoolList/roomDevices'));
const RoomImport = lazy(() => import('pages/schoolList/importRoom'));
const GatewayEdit = lazy(() => import('pages/schoolList/overview/gatewayList/editGateway'));
const GatewayAdd = lazy(() => import('pages/schoolList/overview/gatewayList/addGateway'));
const GatewayImport = lazy(() => import('pages/schoolList/overview/gatewayList/importGateway'));
const TotalMeterEditOrAdd = lazy(() => import('pages/schoolList/editOrAddTotalMeter'));
const SchoolImport = lazy(() => import('pages/schoolList/importSchool'));
const IpcEditOrAdd = lazy(() => import('pages/schoolList/editOrAddIpc'));
const SwitchEditOrAdd = lazy(() => import('pages/schoolList/editOrAddSwitch'));
const DashboardEditOrAdd = lazy(() => import('pages/schoolList/editOrAddDashboard'));
const AvmEditOrAdd = lazy(() => import('pages/schoolList/editOrAddAvm'));
const RouterEditOrAdd = lazy(() => import('pages/schoolList/editOrAddRouter'));
const PM25EditOrAdd = lazy(() => import('pages/schoolList/editOrAddPm25'));
const QuickDeviceList = lazy(() => import('pages/schoolList/quickDeviceList'));
const EditOrAddDevice = lazy(() => import('pages/schoolList/editOrAddDevice'));
const UpdateRoomByExcel = lazy(() => import('pages/schoolList/updateRoomByExcel'));

export const schoolDevicesRoutesConfig: RoutesConfig = [
  {
    path: '/school',
    iconName: 'CityNext',
    title: '學校設備管理',
    permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN, RoleNumberEnum.VENDOR],
    exact: true,
    component: SchoolList,
    children: [
      {
        component: QuickDeviceList,
        path: '/school/:schoolId/device-list',
        iconName: '',
        title: '設備表',
        exact: true,
        asNavTab: true,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN, RoleNumberEnum.VENDOR],
      },
      {
        component: EditOrAddDevice,
        path: '/school/:schoolId/device-list/add',
        iconName: '',
        title: '新增設備',
        exact: true,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: EditOrAddDevice,
        path: '/school/:schoolId/device-list/edit',
        iconName: '',
        title: '編輯設備',
        exact: true,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: SchoolAdd,
        path: '/school/add',
        iconName: '',
        title: '新增學校',
        exact: true,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: SchoolEdit,
        path: '/school/:schoolId/edit',
        iconName: '',
        title: '編輯學校',
        exact: true,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: GatewayAdd,
        path: '/school/:shoolId/overview/gateway/add',
        iconName: '',
        title: '新增轉換器',
        exact: true,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: GatewayImport,
        path: '/school/:schoolId/overview/gateway/import',
        iconName: '',
        title: '匯入轉換器',
        exact: true,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: RoomImport,
        path: '/school/:schoolId/overview/room/import',
        iconName: '',
        title: '匯入教室',
        exact: true,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: UpdateRoomByExcel,
        path: '/school/:schoolId/overview/room/update',
        iconName: '',
        title: '教室更新序號',
        exact: true,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      // RoomDevices 頁面必須放在 RoomImport 下方，否則 url 中的 "import" 會被視為是 :id
      {
        component: RoomDevices,
        path: '/school/:schoolId/overview/room/:id',
        iconName: '',
        title: '教室設備資訊',
        exact: true,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN, RoleNumberEnum.VENDOR],
      },
      {
        component: GatewayEdit,
        path: '/school/:schoolId/overview/gateway/edit',
        iconName: '',
        title: '編輯轉換器',
        exact: true,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: TotalMeterEditOrAdd,
        path: '/school/:schoolId/meter/add',
        iconName: '',
        title: '新增總表',
        exact: true,
        asNavTab: false,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: TotalMeterEditOrAdd,
        path: '/school/:schoolId/meter/edit',
        iconName: '',
        title: '編輯總表',
        exact: true,
        asNavTab: false,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: SurveyInfoEditOrAdd,
        path: '/school/:schoolId/survey/add',
        iconName: '',
        title: '新增場堪基本資訊',
        exact: true,
        asNavTab: false,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: SurveyInfoEditOrAdd,
        path: '/school/:schoolId/survey/edit',
        iconName: '',
        title: '編輯場堪基本資訊',
        exact: true,
        asNavTab: false,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: SchoolImport,
        path: '/school/import',
        iconName: '',
        title: '匯入學校',
        exact: true,
        asNavTab: false,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: IpcEditOrAdd,
        path: `/school/:schoolId/general-devices/${DeviceType.IPC}/edit`,
        iconName: '',
        title: '編輯校園EMS主機',
        exact: true,
        asNavTab: false,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: IpcEditOrAdd,
        path: `/school/:schoolId/general-devices/${DeviceType.IPC}/add`,
        iconName: '',
        title: '新增校園EMS主機',
        exact: true,
        asNavTab: false,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: SwitchEditOrAdd,
        path: `/school/:schoolId/general-devices/${DeviceType.SWITCH}/edit`,
        iconName: '',
        title: '編輯交換器',
        exact: true,
        asNavTab: false,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: SwitchEditOrAdd,
        path: `/school/:schoolId/general-devices/${DeviceType.SWITCH}/add`,
        iconName: '',
        title: '新增交換器',
        exact: true,
        asNavTab: false,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: DashboardEditOrAdd,
        path: `/school/:schoolId/general-devices/${DeviceType.DASHBOARD}/edit`,
        iconName: '',
        title: '編輯看板',
        exact: true,
        asNavTab: false,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: DashboardEditOrAdd,
        path: `/school/:schoolId/general-devices/${DeviceType.DASHBOARD}/add`,
        iconName: '',
        title: '新增看板',
        exact: true,
        asNavTab: false,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: AvmEditOrAdd,
        path: `/school/:schoolId/general-devices/${DeviceType.AVM}/edit`,
        iconName: '',
        title: '編輯儲值機',
        exact: true,
        asNavTab: false,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: AvmEditOrAdd,
        path: `/school/:schoolId/general-devices/${DeviceType.AVM}/add`,
        iconName: '',
        title: '新增儲值機',
        exact: true,
        asNavTab: false,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: RouterEditOrAdd,
        path: `/school/:schoolId/general-devices/${DeviceType.ROUTER}/edit`,
        iconName: '',
        title: '編輯4GRouter',
        exact: true,
        asNavTab: false,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: RouterEditOrAdd,
        path: `/school/:schoolId/general-devices/${DeviceType.ROUTER}/add`,
        iconName: '',
        title: '新增4GRouter',
        exact: true,
        asNavTab: false,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: PM25EditOrAdd,
        path: `/school/:schoolId/general-devices/${DeviceType.PM25}/edit`,
        iconName: '',
        title: '編輯PM2.5',
        exact: true,
        asNavTab: false,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
      {
        component: PM25EditOrAdd,
        path: `/school/:schoolId/general-devices/${DeviceType.PM25}/add`,
        iconName: '',
        title: '新增PM2.5',
        exact: true,
        asNavTab: false,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN],
      },
    ],
  },
];
