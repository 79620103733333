import { RoleNumberEnum } from 'hooks/auth';
import { DeviceType } from 'hooks/device';
import { TotalMeterEnum, TotalMeterPowerSystemEnum } from 'hooks/totalMeter';
import { convertDeviceName } from 'utils';

export const roleOptions = [
  { key: RoleNumberEnum.ADMIN, text: 'Admin' },
  { key: RoleNumberEnum.CONTRACTOR, text: '專案管理員' },
  { key: RoleNumberEnum.MANAGER, text: '工班管理員' },
  { key: RoleNumberEnum.VENDOR, text: '設備商' },
];

export const gatewayModel = {
  威力工業: [
    { key: 'IDS-342GT', text: 'IDS-342GT' },
    { key: 'IDS-312L', text: 'IDS-312L' },
  ],
};

export const meterTypeOptions = [
  { key: TotalMeterEnum.SCHOOL_METER, text: '學校總表' },
  { key: TotalMeterEnum.AC_TOTAL_METER, text: '新設冷氣總表' },
  { key: TotalMeterEnum.PV_METER, text: '太陽光電電表' },
];

export const AllDeviceTypeOptions = [
  { text: convertDeviceName(DeviceType.METER, null), value: DeviceType.METER },
  { text: convertDeviceName(DeviceType.DONGLE, null), value: DeviceType.DONGLE },
  { text: convertDeviceName(DeviceType.CARD_READER, null), value: DeviceType.CARD_READER },
  { text: convertDeviceName(DeviceType.GATEWAY, null), value: DeviceType.GATEWAY },
  { text: convertDeviceName(DeviceType.TOTAL_METER, null), value: DeviceType.TOTAL_METER },
  { text: convertDeviceName(DeviceType.IPC, null), value: DeviceType.IPC },
  { text: convertDeviceName(DeviceType.SWITCH, null), value: DeviceType.SWITCH },
  { text: convertDeviceName(DeviceType.DASHBOARD, null), value: DeviceType.DASHBOARD },
  { text: convertDeviceName(DeviceType.AVM, null), value: DeviceType.AVM },
  { text: convertDeviceName(DeviceType.PM25, null), value: DeviceType.PM25 },
  { text: convertDeviceName(DeviceType.ROUTER, null), value: DeviceType.ROUTER },
  { text: convertDeviceName(DeviceType.LUM, null), value: DeviceType.LUM },
];

export const natureOptions = [
  { key: true, text: '+' },
  { key: false, text: '+-' },
];

export const hasInsiderOptions = [
  { key: true, text: 'Y' },
  { key: false, text: 'N' },
];

export const generalDeviceOptions = [
  { key: DeviceType.SWITCH, text: '交換器' },
  { key: DeviceType.IPC, text: '校園EMS主機' },
  { key: DeviceType.DASHBOARD, text: '即時看板' },
  { key: DeviceType.AVM, text: '儲值機' },
];

export const totalMeterPowerOptions = [
  { key: TotalMeterPowerSystemEnum['3P4W'], text: '3P4W(三相四線式)' },
  { key: TotalMeterPowerSystemEnum['3P3W'], text: '3P3W(三相三線式)' },
  { key: TotalMeterPowerSystemEnum['1P3W'], text: '1P3W(單相三線式)' },
];

export const ctTypeOptions = [
  { key: 2, text: '2 CT' },
  { key: 3, text: '3 CT' },
];

export const totalMeterInstallPlaceOptions = [
  { key: '室內', text: '室內' },
  { key: '室外', text: '室外' },
];

export const dashboardSizeOptions = [
  { key: '55', text: '55吋' },
  { key: '65', text: '65吋' },
];
