import React, { FC } from 'react';
import { FontIcon } from '@fluentui/react/lib/Icon';
import IconWrapper from 'components/iconWrapper';

import { useSidebar } from 'context/sidebarsProvider';
import { SidebarProps } from 'components/sidebar';

import { StyleLaptopSidebar, StyleHead, StyleNavWrapper, NavItem, StyleNavLink } from './styles';

const LaptopSidebar: FC<SidebarProps> = ({ navItems }) => {
  const { laptopSidebarNarrow, setLaptopSidebarNarrow } = useSidebar();

  return (
    <StyleLaptopSidebar narrow={laptopSidebarNarrow}>
      <StyleHead narrow={laptopSidebarNarrow}>
        <FontIcon
          onClick={() => setLaptopSidebarNarrow(!laptopSidebarNarrow)}
          iconName="GlobalNavButton"
          style={{ color: '#fff', fontSize: '24px', cursor: 'pointer', marginRight: '16px' }}
        />
      </StyleHead>
      <StyleNavWrapper>
        {navItems.map(({ url, title, iconName, children, component }) => {
          let urlPath = '';

          if (component) {
            urlPath = url;
          } else {
            const firstChildNavTab = children && children.find(({ asTabNav }) => asTabNav);
            urlPath = firstChildNavTab ? firstChildNavTab.url : url;
          }

          return (
            <StyleNavLink to={urlPath} key={urlPath} isActive={(_, location) => location.pathname.startsWith(url)}>
              <NavItem narrow={laptopSidebarNarrow}>
                <IconWrapper width={24}>
                  <FontIcon iconName={iconName} />
                </IconWrapper>
                <p>{title}</p>
              </NavItem>
            </StyleNavLink>
          );
        })}
      </StyleNavWrapper>
    </StyleLaptopSidebar>
  );
};

export default LaptopSidebar;
