import React, { ReactElement } from 'react';
import { Route } from 'react-router-dom';

import { RoutesConfig } from 'routes/config';
import { RoleNumberEnum } from 'hooks/auth';
import { URL_PREFIX } from 'routes/privateRoutes';

/**
 *  動態生成符合使用者權限的所有 routes，包含 children routes，並且將其扁平化
 * TODO：為符合 clean code 原則，未來希望能把 `permission?.includes(userRoleId)` 拿掉，讓此 function 專注於做一件事就好
 **/
export const generateRoutes = (
  routes: RoutesConfig,
  userRoleId: RoleNumberEnum | undefined,
): { routes: ReactElement[]; paths: string[] } => {
  const flattenConfigRoutes: ReactElement[] = [];
  const flattenRoutesPath: string[] = [];

  const helper = (array: RoutesConfig) => {
    for (let i = 0; i < array.length; i += 1) {
      const route = array[i];
      const { path, component: Component, children, title, permission, exact, ...rest } = route;

      if (Component && userRoleId && permission?.includes(userRoleId)) {
        flattenRoutesPath.push(`${URL_PREFIX}${path}`);

        flattenConfigRoutes.push(
          <Route {...rest} key={path} path={`${URL_PREFIX}${path}`} exact={exact} component={Component} />,
        );
      }

      if (children && children.length > 0) {
        helper(children);
      }
    }
  };

  helper(routes);
  return { routes: flattenConfigRoutes, paths: flattenRoutesPath };
};
