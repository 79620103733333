import axios from 'axios';

import { DeleteDeviceFunc } from './types';

export const deleteRoomDevice: DeleteDeviceFunc = async ({ deviceId, schoolId }) => {
  const res = await axios({
    method: 'DELETE',
    url: `/device/${deviceId}`,
    data: { schoolId },
  });

  return res.data;
};
