import { createGlobalStyle } from 'styled-components';

export const GlobalToastStyles = createGlobalStyle`
  ${({ theme }) => `
    .react-toast-notifications__container {
      z-index: 100000000 !important;
    }
    .react-toast-notifications__toast--error {
      background-color: ${theme.colors.status.error.light} !important;
      .react-toast-notifications__toast__icon-wrapper {
        background-color ${theme.colors.status.error.primary} !important;
      }
    }
    .react-toast-notifications__toast--success {
      background-color: ${theme.colors.status.success.light} !important;
      .react-toast-notifications__toast__icon-wrapper {
        background-color: ${theme.colors.status.success.primary} !important;
      }
    }
    .react-toast-notifications__toast--info {
      background-color: ${theme.colors.status.normal.light} !important;
      .react-toast-notifications__toast__icon-wrapper {
        background-color: ${theme.colors.status.normal.primary} !important;
      }
    }
    .react-toast-notifications__toast--warning {
      background-color: ${theme.colors.status.warning.light} !important;
      .react-toast-notifications__toast__icon-wrapper {
        background-color: ${theme.colors.status.warning.primary} !important;
      }
    }
    .react-toast-notifications__toast {
      color: ${theme.colors.neutral.primary} !important;
    }
  `}
`;
