import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
`;

export const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Header = styled.header`
  position: absolute;
  left: 0;
  right: 0;
  min-height: 72px;
  border-bottom: solid 1px #c8c6c480;
`;

export const Content = styled.div`
  position: absolute;
  top: 72px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
`;
