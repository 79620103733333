import axios from 'axios';

import { LogoutUserFunc, SignInUserFunc, UserDto } from './types';

export const getUser = async (): Promise<UserDto> => {
  const res = await axios({
    method: 'GET',
    url: '/auth/me',
  });

  return res.data.data;
};

export const signInUser: SignInUserFunc = async (payload) => {
  const res = await axios({
    method: 'POST',
    url: '/auth/signin',
    data: payload,
  });

  return res.data;
};

export const logoutUser: LogoutUserFunc = async () => {
  const res = await axios({
    method: 'POST',
    url: '/auth/logout',
  });

  return res.data;
};
