import React, { useMemo, ComponentType, useState, useEffect } from 'react';
import { useLocation, matchPath, useHistory } from 'react-router-dom';

import createCtx from 'utils/createCtx';
import { useSchool } from 'hooks/school';
import { apiResponsePlaceholder } from 'constants/axios';

import { SchoolDevicesState } from './toggleSchoolProvider.type';

export const [useToggleSchool, CtxProvider] = createCtx<SchoolDevicesState>();

// * 為了避免學校裡不同的頁面切換，都必須重複打一次學校 API 拿資料，因此使用 provider 統一處理
const ToggleSchoolProvider: ComponentType = ({ children }) => {
  const [toggleSchoolId, setToggleSchoolId] = useState<number | undefined>(undefined);
  const location = useLocation();
  const { push } = useHistory();

  const {
    schoolListQuery: { data: schoolList = apiResponsePlaceholder, isFetching },
  } = useSchool({ schoolList: { id: toggleSchoolId } });

  const match = useMemo(
    () =>
      matchPath<{ schoolId?: string }>(location.pathname, {
        path: '/console/school/:schoolId',
        exact: false,
      }),
    [location.pathname],
  );

  const toggleSchool = useMemo(() => schoolList.data.find((school) => school.id === toggleSchoolId), [schoolList.data]);

  const isFindingToggleSchool = useMemo(() => {
    return typeof toggleSchoolId === 'number' && isFetching;
  }, [isFetching, toggleSchoolId]);

  // 取得 url 上的 schoolId，若為數字則更新 toggleSchoolId
  useEffect(() => {
    if (match !== null) {
      const convertToggleSchoolId = Number(match.params?.schoolId);
      const isValidSchoolId = !Number.isNaN(convertToggleSchoolId);

      if (!isValidSchoolId) {
        setToggleSchoolId(undefined);
      } else if (isValidSchoolId && toggleSchoolId !== convertToggleSchoolId) {
        setToggleSchoolId(convertToggleSchoolId);
      }
    }
  }, [location, match]);

  // 若 fetch 不到該回學校則導回學校列表
  useEffect(() => {
    const noSchoolFound = !isFetching && !toggleSchool;

    // !TODO: 目前若 :schoolId 不為數字時，並不會導回到學校列表。原因是 '/school/add' 頁面中的 add 會被當成 schoolId
    // 這也導致若使用者手動在 :schoolId 隨意打了字串，會導致停留在 loading
    if (typeof toggleSchoolId === 'number' && noSchoolFound) {
      push({ pathname: '/console/school' });
    }
  }, [toggleSchoolId, toggleSchool, isFetching]);

  const toggleSchoolDataValue = useMemo(
    () => ({ isFetching, toggleSchoolId, toggleSchool, isFindingToggleSchool }),
    [toggleSchoolId, toggleSchool, isFetching, isFindingToggleSchool],
  );

  return (
    <CtxProvider
      value={{
        ...toggleSchoolDataValue,
      }}
    >
      {children}
    </CtxProvider>
  );
};

export default ToggleSchoolProvider;
