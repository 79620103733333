import { useQuery, useQueryClient, useMutation } from 'react-query';

import { SCHOOL_LIST, TOTAL_METER_LIST, QUICK_INSTALL_LIST } from 'constants/query';
import { useToast } from 'hooks/toast';
import { SuccessDto } from 'types/axios';

import { UseTotalMeterRto, GetTotalMeterListQuery } from './types';
import { getTotalMeterList, createTotalMeter, updateTotalMeter, deleteTotalMeter } from './api';

export const useTotalMeter = (query: GetTotalMeterListQuery): UseTotalMeterRto => {
  const { pushApiResErrorToast, pushApiResSuccessToast } = useToast();
  const queryClient = useQueryClient();

  const handleMutationSuccess = (success: SuccessDto<unknown>) => {
    queryClient.invalidateQueries(TOTAL_METER_LIST);
    queryClient.invalidateQueries(SCHOOL_LIST);
    queryClient.invalidateQueries(QUICK_INSTALL_LIST);
    pushApiResSuccessToast(success);
  };

  const enabledTotalMeterListQuery =
    typeof query.id === 'number' || typeof query.schoolId === 'number' || typeof query.id === 'number';

  const totalMeterListQuery: UseTotalMeterRto['totalMeterListQuery'] = useQuery(
    [TOTAL_METER_LIST, query],
    getTotalMeterList,
    {
      onError: (error) => pushApiResErrorToast(error),
      enabled: enabledTotalMeterListQuery,
      retry: false,
    },
  );

  const totalMeterCreateMutation: UseTotalMeterRto['totalMeterCreateMutation'] = useMutation(createTotalMeter, {
    onSuccess: handleMutationSuccess,
    onError: (error) => pushApiResErrorToast(error),
  });

  const totalMeterUpdateMutation: UseTotalMeterRto['totalMeterUpdateMutation'] = useMutation(updateTotalMeter, {
    onSuccess: handleMutationSuccess,
    onError: (error) => pushApiResErrorToast(error),
  });

  const totalMeterDeleteMutation: UseTotalMeterRto['totalMeterDeleteMutation'] = useMutation(deleteTotalMeter, {
    onSuccess: handleMutationSuccess,
    onError: (error) => pushApiResErrorToast(error),
  });

  return {
    totalMeterListQuery,
    totalMeterCreateMutation,
    totalMeterUpdateMutation,
    totalMeterDeleteMutation,
  };
};
