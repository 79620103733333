import React, { FC } from 'react';
import { FieldProps } from 'formik';
import {
  FormikDropdown,
  FormikSpinButton,
  FormikSpinButtonProps,
  FormikTextField,
} from 'formik-office-ui-fabric-react';

import { textFieldStyles } from './styles';
import { FormikDropdownProps } from './types';

export const TextField: FC<FieldProps> = (props) => {
  return <FormikTextField {...props} styles={textFieldStyles} />;
};
export const DropdownField: FC<FormikDropdownProps> = (props) => (
  <FormikDropdown
    {...props}
    {...(props.options?.length > 0 ? { placeholder: props.placeholder } : { placeholder: '目前無任何選項' })}
  />
);
export const SpinButtonField: FC<FormikSpinButtonProps> = (props) => <FormikSpinButton {...props} />;
