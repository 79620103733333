import styled from 'styled-components';

export const ActionBlock = styled.div`
  text-align: right;
  button {
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
