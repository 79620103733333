import React, { FC, Fragment } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { CommandButton, FontIcon, IContextualMenuProps } from '@fluentui/react';

import { useSidebar } from 'context/sidebarsProvider';
import { NavItem } from 'components/privateLayout';
import { useAuth } from 'hooks/auth';

import { StyleHeader, SubNavWrapper, StyleNavLink, ContentWrapper, HeaderGlobalStyles } from './styles';
import { HeaderProps } from './types';

const Header: FC<HeaderProps> = ({ navItems }) => {
  const { isMobile, setMobileSidebarExpand } = useSidebar();
  const { pathname } = useLocation();
  const {
    userLogoutMutation,
    userQuery: { data: user },
  } = useAuth();

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: 'logout',
        text: '登出',
        iconProps: { iconName: 'OutOfOffice' },
        className: 'topLine',
        onClick: () => userLogoutMutation.mutate({}),
      },
    ],
  };

  const renderTabNav = (routes: NavItem['children']) => {
    if (!routes) {
      return <></>;
    }

    let params = {};

    // 取得目前所在位置所包含的任何 params
    routes.forEach(({ url }) => {
      const match = matchPath(location.pathname, { path: url, exact: false });

      if (match?.params) {
        params = match.params;
      }
    });

    return routes
      .filter(({ asTabNav }) => asTabNav)
      .map(({ url, title }) => {
        for (const [key, value] of Object.entries(params)) {
          url = url.replace(`:${key}`, value as string);
        }

        return (
          <StyleNavLink to={url} key={url}>
            <p>{title}</p>
          </StyleNavLink>
        );
      });
  };

  return (
    <StyleHeader>
      <HeaderGlobalStyles />

      {isMobile && (
        <FontIcon
          onClick={() => setMobileSidebarExpand(true)}
          iconName="GlobalNavButton"
          style={{ color: '#fff', fontSize: '24px', cursor: 'pointer' }}
        />
      )}

      {!isMobile && (
        <ContentWrapper>
          <SubNavWrapper>
            {navItems.map(({ children, component, url }) => {
              if (pathname === url) {
                // 當所在頁面在第一層route時
                // 若第一層route沒有component的話，並且有children routes
                if (!component && children && pathname.startsWith(url)) {
                  // 尋找children routes中是否有tabNav屬性的頁面，有的話顯示在tabNav上
                  return renderTabNav(children);
                }
              } else {
                // 當所在頁面在第二層route時
                const matchChildRouteIndex = children?.findIndex((child) => {
                  const match = matchPath(location.pathname, {
                    path: child.url,
                    exact: false,
                  });

                  return match && child.asTabNav;
                });

                if (matchChildRouteIndex !== -1 && children) {
                  // 尋找children routes中是否有tabNav屬性的頁面，有的話顯示在tabNav上
                  return renderTabNav(children);
                }
              }

              return <Fragment key={url} />;
            })}
          </SubNavWrapper>
          <CommandButton text={user?.name} menuProps={menuProps} ariaLabel="Header Dropdown" />
        </ContentWrapper>
      )}
    </StyleHeader>
  );
};

export default Header;
