import styled from 'styled-components';
import { Heading } from 'oring-ui/lib/components';
import { themePrimary } from 'oring-ui/lib/styles/variables';

export const PageTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }
`;

export const LastFlexItemWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const PageTitle = styled(Heading)`
  margin-bottom: 24px;
`;

PageTitle.defaultProps = {
  major: 2,
  color: themePrimary,
};
