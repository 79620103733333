import { useEffect } from 'react';
import { useToasts, RemoveToast } from 'react-toast-notifications';

import { UseToastRto, ToastStack } from './types';

export const useToast = (): UseToastRto => {
  const { addToast, toastStack, removeToast } = useToasts();

  const removeDuplicatedToast = (toastStack: ToastStack, removeToast: RemoveToast) => {
    const findUniqueAndRemoveToast = toastStack.reduce(
      (acc, cur) => {
        const newAcc = { ...acc };

        if (!acc.remained.some(({ content }) => content === cur.content)) {
          newAcc.remained.push(cur);
        } else {
          newAcc.duplicated.push(cur);
        }

        return newAcc;
      },
      { duplicated: [], remained: [] } as { duplicated: ToastStack; remained: ToastStack },
    );

    findUniqueAndRemoveToast.duplicated.forEach(({ id }) => {
      removeToast(id);
    });
  };

  const pushErrorToast: UseToastRto['pushErrorToast'] = (msg: string, options) => {
    addToast(msg, { appearance: 'error', ...options });
  };

  const pushSuccessToast: UseToastRto['pushSuccessToast'] = (msg: string, options) => {
    addToast(msg, { appearance: 'success', ...options });
  };

  const pushApiResSuccessToast: UseToastRto['pushApiResSuccessToast'] = (success, options = {}) => {
    const successMessage = success?.message || '成功執行指令';

    addToast(successMessage, { appearance: 'success', ...options });
  };

  const pushApiResErrorToast: UseToastRto['pushApiResErrorToast'] = (error, options = {}) => {
    const errorRes = error.response?.data;
    const errorMessage =
      errorRes?.status && errorRes?.message
        ? `[${errorRes.status}] ${errorRes.message}`
        : '或許伺服器在更新中，晚點再試試看吧！';

    addToast(errorMessage, { appearance: 'error', ...options });
  };

  useEffect(() => {
    removeDuplicatedToast(toastStack, removeToast);
  }, [toastStack]);

  return { pushApiResErrorToast, pushApiResSuccessToast, pushErrorToast, pushSuccessToast };
};
