import React from 'react';
import { registerIcons, unregisterIcons } from '@fluentui/react';

import {
  BsPencil,
  BsPlus,
  BsArrowLeft,
  BsBarChart,
  BsBuilding,
  BsFileText,
  BsList,
  BsSearch,
  BsArrowsFullscreen,
} from 'react-icons/bs';
import { VscTrash } from 'react-icons/vsc';
import { IoImageOutline } from 'react-icons/io5';
import { AiFillCheckCircle } from 'react-icons/ai';

// unregister fluent default icons
unregisterIcons([
  'add',
  'edit',
  'delete',
  'Back',
  'BarChartVerticalFill',
  'CityNext',
  'CustomList',
  'GlobalNavButton',
  'search',
  'photo',
]);

// register third-party icons
registerIcons({
  icons: {
    add: <BsPlus />,
    edit: <BsPencil />,
    delete: <VscTrash />,
    Back: <BsArrowLeft />,
    BarChartVerticalFill: <BsBarChart />,
    CityNext: <BsBuilding />,
    CustomList: <BsFileText />,
    GlobalNavButton: <BsList />,
    search: <BsSearch />,
    BsArrowsFullscreen: <BsArrowsFullscreen />,
    photo: <IoImageOutline />,
    AiFillCheckCircle: <AiFillCheckCircle />,
  },
});
