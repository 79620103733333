import { ITextFieldProps } from 'office-ui-fabric-react';
import { themePrimary, errorPrimary, neutralPrimary } from 'oring-ui/lib/styles/variables';
import styled from 'styled-components';

const TEXT_FIELD_MAX_WIDTH = '320px';

export const textFieldStyles: ITextFieldProps['styles'] = {
  root: {
    maxWidth: TEXT_FIELD_MAX_WIDTH,
    selectors: {
      '&.is-disabled input': {
        borderColor: '#edebe9',
        backgroundColor: '#edebe9',
        color: '#00000066',
      },
      '&.is-disabled input:hover': {
        borderColor: '#edebe9',
        backgroundColor: '#edebe9',
        color: '#00000066',
      },
      '&.is-active input': {
        backgroundColor: '#fff',
      },
      '&.is-active input[aria-invalid="false"]': {
        boxShadow: '0 0 4px 0 rgba(31, 38, 120, 0.3)',
        borderColor: themePrimary,
      },
      '&.is-active input[aria-invalid="true"]': {
        boxShadow: '0 0 4px 0 rgba(195, 15, 28, 0.3)',
      },
    },
  },
  errorMessage: {
    color: errorPrimary,
  },
  fieldGroup: {
    border: '0',
    backgroundColor: '#fff',
    selectors: {
      ':after': {
        border: 'none',
      },
    },
  },
  field: {
    border: `1px solid ${neutralPrimary}`,
    selectors: {
      '&::placeholder': {
        color: '#00000066',
      },
      '&[aria-invalid="true"]': {
        borderColor: errorPrimary,
      },
    },
  },
  suffix: {
    backgroundColor: '#f3f1f0',
    color: 'rgba(0, 0, 0, 0.6)',
    position: 'absolute',
    right: '1px',
    top: '1px',
    height: '30px',
  },
};

export const FieldsWrapper = styled.div`
  max-width: ${TEXT_FIELD_MAX_WIDTH};
`;

export const SectionTitle = styled.h4`
  color: ${({ theme }) => theme.colors.neutral.primary};
  font-size: 24px;
  font-weight: 600;
`;

export const SideBySideFieldsWrapper = styled.div`
  display: flex;

  > div {
    margin-right: 1em;

    &:last-child {
      margin-right: 0;
    }
  }
`;
