import React, { FC } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Spinner } from 'oring-ui/lib/components';

import { useAuth } from 'hooks/auth';
import InterceptorProvider from 'context/interceptorProvider';

import { PrivateRoutes } from './privateRoutes';
import { PublicRoutes } from './publicRoutes';

const AppRouter: FC = () => {
  const { userQuery, isLoggedIn } = useAuth();

  if (userQuery.isLoading) {
    return <Spinner layout="fullScreen" />;
  }

  return (
    <Router>
      <Switch>
        {isLoggedIn ? (
          <InterceptorProvider>
            <Route path="/" component={PrivateRoutes} />
          </InterceptorProvider>
        ) : (
          <Route path="/" component={PublicRoutes} />
        )}
      </Switch>
    </Router>
  );
};

export default AppRouter;
