import styled from 'styled-components';
import { Box } from 'oring-ui/lib/components';
import { themePrimary } from 'oring-ui/lib/styles/variables';

export const Card = styled(Box)`
  min-width: 340px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  padding: 40px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    background-color: ${({ theme }) => theme.colors.support.primary};
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const CardTitle = styled.h2`
  font-size: 32px;
  font-weight: bold;
  color: ${themePrimary};
  margin-bottom: 24px;
  text-align: center;
`;
