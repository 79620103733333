import styled from 'styled-components';
import { Box } from 'oring-ui/lib/components';

import { LANDSCAPE_MOBILE } from 'constants/breakPoints';

export const Paper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  padding: 40px;
  position: relative;
  @media (max-width: ${LANDSCAPE_MOBILE}) {
    padding: 20px;
  }
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    background-color: ${({ theme }) => theme.colors.support.primary};
    position: absolute;
    top: 0;
    left: 0;
  }
`;
