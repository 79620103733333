import { useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { USER } from 'constants/query';
import { SuccessDto } from 'types/axios';
import { useToast } from 'hooks/toast';

import { UseAuthRto, SignInRto } from './types';
import { getUser, signInUser, logoutUser } from './api';

export const useAuth = (): UseAuthRto => {
  const { pushApiResErrorToast, pushApiResSuccessToast } = useToast();
  const queryClient = useQueryClient();

  const handleSignInSuccess = async (success: SuccessDto<SignInRto>) => {
    pushApiResSuccessToast(success);
    localStorage.setItem('token', success.data.accessToken);
    await queryClient.invalidateQueries(USER);
  };

  const handleLogoutSuccess = async (success: SuccessDto<string>) => {
    pushApiResSuccessToast(success);
    localStorage.removeItem('token');
    queryClient.setQueryData(USER, undefined);
  };

  const userQuery: UseAuthRto['userQuery'] = useQuery(USER, getUser, {
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    enabled: typeof localStorage.getItem('token') === 'string',
  });

  const userSignInMutation: UseAuthRto['userSignInMutation'] = useMutation(signInUser, {
    onSuccess: handleSignInSuccess,
    onError: (error) => pushApiResErrorToast(error),
    retry: false,
  });

  const userLogoutMutation: UseAuthRto['userLogoutMutation'] = useMutation(logoutUser, {
    onSuccess: handleLogoutSuccess,
    onError: (error) => pushApiResErrorToast(error),
    retry: false,
  });

  const isLoggedIn = useMemo(() => userQuery.data !== undefined, [userQuery.data]);

  return {
    userQuery,
    userSignInMutation,
    userLogoutMutation,
    isLoggedIn,
  };
};
