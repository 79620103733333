import { SchoolType } from './types';

export const schoolTypeOptions = [
  { key: SchoolType.ELEMENTARY, text: '國小' },
  { key: SchoolType.JUNIOR, text: '國中' },
  { key: SchoolType.SENIOR, text: '高中' },
  { key: SchoolType.VOCATIONAL, text: '高職' },
  { key: SchoolType.SIX_YEAR, text: '完全中學' },
  { key: SchoolType.NINE_YEAR, text: '國中國小' },
];
