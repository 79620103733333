import React, { FC } from 'react';
import styled from 'styled-components';

import { IconWrapperProps } from './types';

const StyleIconWrapper = styled.div<IconWrapperProps>`
  display: flex;
  justify-content: center;
  min-width: ${({ width }) => `${width}px`};
`;

const IconWrapper: FC<IconWrapperProps> = ({ children, width }) => {
  return <StyleIconWrapper width={width}>{children}</StyleIconWrapper>;
};

export default IconWrapper;
