import React, { FC } from 'react';

import Sidebar from 'components/sidebar';
import Header from 'components/header';
import SidebarProvider from 'context/sidebarsProvider';

import { Wrapper, Main, Content } from './styles';
import { PrivateLayoutProps, NavItem } from './types';

const PrivateLayout: FC<PrivateLayoutProps> = ({ children, routes, pathPrefix }) => {
  const navItems = routes.flat().map(({ title, path, component, iconName, children }) => {
    const navItem: NavItem = { title, iconName, component, url: `${pathPrefix}${path}` };

    if (children && children.length > 0) {
      navItem.children = children.map((child) => ({
        title: child.title,
        iconName: child.iconName,
        asTabNav: !!child.asNavTab,
        url: `${pathPrefix}${child.path}`,
        component: child.component,
      }));
    }

    return navItem;
  });

  return (
    <Wrapper>
      <SidebarProvider>
        <Sidebar navItems={navItems} />
        <Main>
          <>
            <Header navItems={navItems} />
            <Content>{children}</Content>
          </>
        </Main>
      </SidebarProvider>
    </Wrapper>
  );
};

export default PrivateLayout;
