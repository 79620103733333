import { lazy } from 'react';

import { RoutesConfig } from 'routes/config';
import { RoleNumberEnum } from 'hooks/auth';

const ContractorManagement = lazy(() => import('pages/admin/contractorMgmt'));
const ContractorEditOrAdd = lazy(() => import('pages/admin/contractorMgmt/editOrAddContractor'));
const ContractorDeployProgress = lazy(() => import('pages/admin/contractorDeployProgress'));
const ManagerManagement = lazy(() => import('pages/manager/managerMgmt'));
const ManagerAdd = lazy(() => import('pages/manager/addManager'));
const ManagerEdit = lazy(() => import('pages/manager/editManager'));
const ManagerDeployProgress = lazy(() => import('pages/manager/managerDeployProgress'));
const Inventory = lazy(() => import('pages/inventory'));
const ImportInventoryDevice = lazy(() => import('pages/inventory/importInventoryDevice'));
const WorkerCompanyList = lazy(() => import('pages/worker/workerCompanyList'));
const WorkerCompanyEditOrAdd = lazy(() => import('pages/worker/workerCompanyList/editOrAddWorkerCompany'));
const WorkerDeployProgress = lazy(() => import('pages/worker/workerDeployProgress'));
const VendorList = lazy(() => import('pages/vendorList'));
const CityProgress = lazy(() => import('pages/cityProgress'));
const WorkerProgress = lazy(() => import('pages/workerProgress'));

export const otherRoutesConfig: RoutesConfig = [
  {
    component: ContractorManagement,
    path: '/contractor-management',
    iconName: 'AccountManagement',
    title: '專案管理員管理',
    exact: true,
    permission: [RoleNumberEnum.ADMIN],
    children: [
      {
        component: ContractorEditOrAdd,
        path: '/contractor-management/edit',
        iconName: '',
        title: '編輯專案管理員',
        exact: true,
        permission: [RoleNumberEnum.ADMIN],
      },
      {
        component: ContractorEditOrAdd,
        path: '/contractor-management/add',
        iconName: '',
        title: '新增專案管理員',
        exact: true,
        permission: [RoleNumberEnum.ADMIN],
      },
      {
        component: ContractorDeployProgress,
        path: '/contractor-management/:contractorId',
        iconName: '',
        title: '專案管理員安裝進度',
        exact: true,
        permission: [RoleNumberEnum.ADMIN],
      },
    ],
  },
  {
    component: VendorList,
    path: '/vendor-list',
    iconName: 'ServerProcesses',
    title: '設備商管理',
    exact: true,
    permission: [RoleNumberEnum.ADMIN, RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER],
  },
  {
    component: ManagerManagement,
    path: '/manager-management',
    iconName: 'ConnectContacts',
    title: '工班管理員管理',
    exact: true,
    permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.ADMIN],
    children: [
      {
        component: ManagerEdit,
        path: '/manager-management/edit',
        iconName: '',
        title: '編輯工班管理員',
        exact: true,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.ADMIN],
      },
      {
        component: ManagerAdd,
        path: '/manager-management/add',
        iconName: '',
        title: '新增工班管理員',
        exact: true,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.ADMIN],
      },
      {
        component: ManagerDeployProgress,
        path: '/manager-management/:managerId',
        iconName: '',
        title: '工班管理員安裝進度',
        exact: true,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.ADMIN],
      },
    ],
  },
  {
    component: WorkerCompanyList,
    path: '/worker-company-list',
    iconName: 'CRMServices',
    title: '工班管理',
    exact: true,
    permission: [RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN, RoleNumberEnum.CONTRACTOR],
    children: [
      {
        component: WorkerCompanyEditOrAdd,
        path: '/worker-company-list/add',
        iconName: '',
        title: '新增工班',
        exact: true,
        permission: [RoleNumberEnum.MANAGER],
      },
      {
        component: WorkerCompanyEditOrAdd,
        path: '/worker-company-list/edit',
        iconName: '',
        title: '編輯工班',
        exact: true,
        permission: [RoleNumberEnum.MANAGER],
      },
      {
        component: WorkerDeployProgress,
        path: '/worker-company-list/:workerId',
        iconName: '',
        title: '工班安裝進度',
        exact: true,
        permission: [RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN, RoleNumberEnum.CONTRACTOR],
      },
    ],
  },
  {
    component: Inventory,
    path: '/inventory',
    iconName: 'CustomList',
    title: '設備清單',
    exact: true,
    permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.MANAGER, RoleNumberEnum.ADMIN, RoleNumberEnum.VENDOR],
    children: [
      {
        component: ImportInventoryDevice,
        path: '/inventory/import',
        iconName: '',
        title: '匯入設備',
        exact: true,
        permission: [RoleNumberEnum.CONTRACTOR, RoleNumberEnum.ADMIN, RoleNumberEnum.VENDOR],
      },
    ],
  },
  {
    path: '/progress',
    iconName: 'BarChartVerticalFill',
    title: '安裝進度',
    exact: true,
    permission: [RoleNumberEnum.ADMIN, RoleNumberEnum.CONTRACTOR],
    children: [
      {
        component: CityProgress,
        path: '/progress/city',
        iconName: '',
        title: '縣市進度',
        exact: true,
        asNavTab: true,
        permission: [RoleNumberEnum.ADMIN, RoleNumberEnum.CONTRACTOR],
      },
      {
        component: WorkerProgress,
        path: '/progress/worker',
        iconName: '',
        title: '工班進度',
        exact: true,
        asNavTab: true,
        permission: [RoleNumberEnum.ADMIN, RoleNumberEnum.CONTRACTOR],
      },
    ],
  },
];
