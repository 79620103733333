import { UseMutationResult } from 'react-query';

import { AxiosErrorDto, SuccessDto } from 'types/axios';
import { ImageDto } from 'types/api';
import { RoomDto } from 'hooks/room';

export type DeviceDto = {
  type: DeviceType;
  onOff?: boolean;
  serialNumber?: string;
  slaveId?: number;
  isCompleted?: boolean;
  images: ImageDto[];
  vendorId?: number;
  schoolId?: number;
  classroomId?: number;
  gatewayId?: number;
};

export enum DeviceType {
  SCHOOL_METER = 'school_total_meter',
  AIR_CONDITIONER_TOTAL_METER = 'ac_total_meter',
  PV_METER = 'pv_total_meter',
  METER = 'class_meter',
  DONGLE = 'dongle',
  CARD_READER = 'card_reader',
  GATEWAY = 'gateway',
  IPC = 'ipc',
  SWITCH = 'switch',
  DASHBOARD = 'dashboard',
  TOTAL_METER = 'total_meter',
  AVM = 'deposit_system',
  ROUTER = 'router',
  PM25 = 'pm25',
  ROOM = 'room',
  CONTROLLER = 'controller',
  LUM = 'lum',
}

export type CalculateDevicesCompleteNumberFunc = (room: RoomDto) => {
  isDeployedNumber: number;
  totalDevicesCount: number;
  rate: string;
  status: 'normal' | 'default';
};

// delete
export type DeleteDevicePayload = { deviceId: number; schoolId: number };
export type DeleteDeviceFunc = (payload: DeleteDevicePayload) => Promise<SuccessDto<object>>;

export type UseDeviceRto = {
  deviceDeleteMutation: UseMutationResult<SuccessDto<object>, AxiosErrorDto, DeleteDevicePayload>;
};
