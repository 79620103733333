import { intersection } from 'lodash';

import { RoleNumberEnum } from 'hooks/auth';
import { RoutesConfig } from 'routes/config';

/**
 * @param  {RoutesConfig} routes
 * @param  {RoleNumberEnum|undefined} roles
 * @returns RoutesConfig
 *
 * TODO：目前只針對第一層做 permission filter，未來最好能針對 nested children 也做 filter
 */
export const filterOutInsufficientPermissionRoutes = (
  routes: RoutesConfig,
  roles: RoleNumberEnum | undefined,
): RoutesConfig => {
  const isArrayWithLength = (array: unknown[]): boolean => {
    return Array.isArray(array) && !!array.length;
  };

  return routes.filter(({ permission }) => {
    if (!permission) return true;
    else if (!isArrayWithLength(permission)) return true;
    else return intersection(permission, [roles]).length;
  });
};
