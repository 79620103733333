import { DropdownWithGroupOptions } from 'components/reactSelect/dropdownWithGroup';

import { SchoolDropdownOptionsDto } from './types';

export const generateSchoolGroupOptions = (schoolOptions: SchoolDropdownOptionsDto[]): DropdownWithGroupOptions => {
  const groupOptions: DropdownWithGroupOptions = [];

  schoolOptions.forEach(({ city, id, name, address }) => {
    const groupIndex = groupOptions.findIndex(({ label }) => label === city.name);

    if (groupIndex === -1) {
      groupOptions.push({
        label: city.name,
        options: [
          {
            label: name || '',
            value: id,
            description: address,
          },
        ],
      });
    } else {
      groupOptions[groupIndex].options.push({
        label: name || '',
        value: id,
        description: address,
      });
    }
  });

  return groupOptions;
};
