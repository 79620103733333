export const calculateArrayDifferent = (
  currentArray: number[],
  newArray: number[],
): { removeIds: number[]; addIds: number[] } => {
  const removeIds = currentArray.reduce((acc: number[], id) => {
    return newArray.indexOf(id) === -1 ? [...acc, id] : acc;
  }, []);

  const addIds = newArray.reduce((acc: number[], id) => {
    return currentArray.indexOf(id) === -1 ? [...acc, id] : acc;
  }, []);

  return { removeIds, addIds };
};
