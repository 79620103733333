import styled from 'styled-components';
import { themePrimary } from 'oring-ui/lib/styles/variables';

export const PublicLayoutWrapper = styled.div`
  position: relative;
  height: 100vh;

  .bg {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > div {
      width: 100%;
    }

    .bg-top {
      position: relative;
      height: 68%;

      &:after {
        content: '';
        position: absolute;
        bottom: 0px;
        border-style: solid;
        border-width: 0 0 200px 100vw;
        border-color: transparent transparent #161d44 transparent;
      }
    }

    .bg-bottom {
      background-color: #161d44;
      flex: 1;
    }
  }
`;

export const CenterBlock = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const TitleBlock = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  color: ${themePrimary};

  .title {
    font-size: 42px;
    font-weight: 300;
    margin-bottom: 8px;
    text-align: center;
  }

  .version {
    margin-left: 2px;
    font-size: 10px;
  }
`;
