export const apiResponsePlaceholder = {
  data: [],
  paging: {
    beforeCursor: null,
    afterCursor: null,
  },
  metadata: {
    total: 0,
  },
};
