import React, { useState, useMemo, ComponentType, useEffect } from 'react';

import createCtx from 'utils/createCtx';
import { LANDSCAPE_MOBILE, LANDSCAPE_TABLET } from 'constants/breakPoints';

import { SidebarContextState } from './sidebarsProvider.type';

export const [useSidebar, CtxProvider] = createCtx<SidebarContextState>();

const mobileScreen = window.matchMedia(`(max-width: ${LANDSCAPE_MOBILE})`);

const smallScreen = window.matchMedia(`(max-width: ${LANDSCAPE_TABLET})`);

const SidebarProvider: ComponentType = ({ children }) => {
  const [mobileSidebarExpand, setMobileSidebarExpand] = useState(false);
  const [laptopSidebarNarrow, setLaptopSidebarNarrow] = useState(true);
  const [isMobile, setMobile] = useState(false);

  const mobileBrowserWidthChange = () => {
    setMobile(() => mobileScreen.matches);
    if (!mobileScreen.matches) setLaptopSidebarNarrow(true);
  };

  const smallBrowserWidthChange = () => {
    setLaptopSidebarNarrow(smallScreen.matches);
  };

  // close sidebar while screen width getting change above ${LANDSCAPE_MOBILE}
  useEffect(() => {
    if (isMobile) setLaptopSidebarNarrow(false);
    else setMobileSidebarExpand(false);
  }, [isMobile]);

  // useEffect(() => {
  //   mobileScreen.addEventListener('change', mobileBrowserWidthChange);
  //   smallScreen.addEventListener('change', smallBrowserWidthChange);

  //   return () => {
  //     mobileScreen.removeEventListener('change', mobileBrowserWidthChange);
  //     smallScreen.removeEventListener('change', smallBrowserWidthChange);
  //   };
  // }, []);

  const SidebarDataValue = useMemo(
    () => ({ mobileSidebarExpand, laptopSidebarNarrow, isMobile }),
    [mobileSidebarExpand, laptopSidebarNarrow, isMobile],
  );

  return (
    <CtxProvider
      value={{
        ...SidebarDataValue,
        setMobileSidebarExpand,
        setLaptopSidebarNarrow,
      }}
    >
      {children}
    </CtxProvider>
  );
};

export default SidebarProvider;
