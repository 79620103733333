import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';

import Login from 'pages/login';

export const PublicRoutes: FC = () => (
  <>
    <Route path="/login" exact component={Login} />
    <Redirect path="*" to="/login" />
  </>
);
