import React, { FC } from 'react';

import { useSidebar } from 'context/sidebarsProvider';

import LaptopSidebar from './laptopSidebar';
import MobileSidebar from './mobileSidebar';
import { SidebarProps } from './types';

const Sidebar: FC<SidebarProps> = ({ navItems }) => {
  const { isMobile } = useSidebar();

  return isMobile ? <MobileSidebar navItems={navItems} /> : <LaptopSidebar navItems={navItems} />;
};

export default Sidebar;
