import styled, { createGlobalStyle } from 'styled-components';
import { themeDark, themePrimary, support } from 'oring-ui/lib/styles/variables';

import { LANDSCAPE_MOBILE } from 'constants/breakPoints';
import { NavLink } from 'react-router-dom';

export const HeaderGlobalStyles = createGlobalStyle`
  .topLine {
    border-top: 1px solid #edebe9;
  }
  .bottomLine {
    border-bottom: 1px solid #edebe9;
  }
`;

export const StyleHeader = styled.header`
  position: absolute;
  left: 0;
  right: 0;
  height: 72px;
  padding: 0 24px;
  border-bottom: solid 1px #c8c6c480;
  transition: 0.3s;

  @media (max-width: ${LANDSCAPE_MOBILE}) {
    height: 60px;
    background-color: ${themeDark};
    padding: 0 24px;
    display: flex;
    align-items: center;
  }
`;

export const ContentWrapper = styled.nav`
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SubNavWrapper = styled.div`
  display: flex;
  height: 100%;
  transition: 0.3s;
`;

export const StyleNavLink = styled(NavLink)`
  position: relative;
  padding: 0 16px;
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  transition: 0.3s;

  &::after {
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    border-bottom: 2px solid ${support.primary};
    width: 80%;
    transition: 0.3s;
  }

  &:hover {
    background-color: ${support.lightAlt};
  }

  &.active {
    color: ${themePrimary};

    &::after {
      opacity: 1;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }
  }
`;
