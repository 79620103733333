import { useQuery, useQueryClient, useMutation } from 'react-query';

import { MANAGER_LIST, SCHOOL_DROPDOWN_OPTIONS, SCHOOL_LIST, SCHOOL_PROGRESS } from 'constants/query';
import { useToast } from 'hooks/toast';
import { SuccessDto } from 'types/axios';

import { UseSchoolRto, UseSchoolQuery, DownloadSchoolReportResDto } from './types';
import {
  createSchool,
  deleteSchool,
  getSchoolList,
  updateSchool,
  updateSchoolSurveyInfo,
  uploadImageToSchool,
  deleteImageFromSchool,
  downloadSchoolTemplate,
  importSchool,
  assignUserToSchool,
  downloadSchoolReport,
  getSchoolDropdownOptions,
  getSchoolProgress,
  DownloadDeviceTable,
  importStreetLight,
} from './api';

export const useSchool = (query: UseSchoolQuery): UseSchoolRto => {
  const { pushApiResErrorToast, pushApiResSuccessToast } = useToast();
  const queryClient = useQueryClient();

  const handleMutationSuccess = (success: SuccessDto<unknown>) => {
    queryClient.invalidateQueries(SCHOOL_LIST);
    queryClient.invalidateQueries(MANAGER_LIST);
    pushApiResSuccessToast(success);
  };

  const handleDownloadSchoolTemplateSuccess = (blob: Blob) => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', '批量匯入學校模板.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadReportSuccess = (res: DownloadSchoolReportResDto) => {
    const url = window.URL.createObjectURL(new Blob([res.attachment]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', res.filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const schoolListQuery: UseSchoolRto['schoolListQuery'] = useQuery([SCHOOL_LIST, query.schoolList], getSchoolList, {
    keepPreviousData: true,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: typeof query.schoolList?.limit === 'number' || typeof query.schoolList?.id === 'number',
    onError: (error) => pushApiResErrorToast(error),
  });

  const schoolDropdownOptionsQuery: UseSchoolRto['schoolDropdownOptionsQuery'] = useQuery(
    [SCHOOL_DROPDOWN_OPTIONS, query.schoolDropdownOptions],
    getSchoolDropdownOptions,
    {
      keepPreviousData: true,
      retry: false,
      enabled: !!query.schoolDropdownOptions,
      onError: (error) => pushApiResErrorToast(error),
    },
  );

  const schoolProgressQuery: UseSchoolRto['schoolProgressQuery'] = useQuery(
    [SCHOOL_PROGRESS, query.schoolProgress],
    getSchoolProgress,
    {
      keepPreviousData: true,
      retry: false,
      enabled: typeof query.schoolProgress?.id === 'number',
      onError: (error) => pushApiResErrorToast(error),
    },
  );

  const userAssignSchoolMutation: UseSchoolRto['userAssignSchoolMutation'] = useMutation(assignUserToSchool, {
    onSuccess: handleMutationSuccess,
    onError: (error) => pushApiResErrorToast(error),
  });

  const schoolCreateMutation: UseSchoolRto['schoolCreateMutation'] = useMutation(createSchool, {
    onSuccess: handleMutationSuccess,
    onError: (error) => pushApiResErrorToast(error),
  });

  const schoolUpdateMutation: UseSchoolRto['schoolUpdateMutation'] = useMutation(updateSchool, {
    onSuccess: handleMutationSuccess,
    onError: (error) => pushApiResErrorToast(error),
  });

  const schoolDeleteMutation: UseSchoolRto['schoolDeleteMutation'] = useMutation(deleteSchool, {
    onSuccess: handleMutationSuccess,
    onError: (error) => pushApiResErrorToast(error),
  });

  const schoolSurveyInfoUpdateMutation: UseSchoolRto['schoolSurveyInfoUpdateMutation'] = useMutation(
    updateSchoolSurveyInfo,
    {
      onSuccess: handleMutationSuccess,
      onError: (error) => pushApiResErrorToast(error),
    },
  );

  const schoolUploadImageMutation: UseSchoolRto['schoolUploadImageMutation'] = useMutation(uploadImageToSchool, {
    onSuccess: handleMutationSuccess,
    onError: (error) => pushApiResErrorToast(error),
  });

  const schoolDeleteImageMutation: UseSchoolRto['schoolDeleteImageMutation'] = useMutation(deleteImageFromSchool, {
    onSuccess: handleMutationSuccess,
    onError: (error) => pushApiResErrorToast(error),
  });

  const schoolTemplateDownloadMutation: UseSchoolRto['schoolTemplateDownloadMutation'] = useMutation(
    downloadSchoolTemplate,
    {
      onSuccess: handleDownloadSchoolTemplateSuccess,
      onError: (error) => pushApiResErrorToast(error),
    },
  );

  const schoolImportMutation: UseSchoolRto['schoolImportMutation'] = useMutation(importSchool, {
    onSuccess: handleMutationSuccess,
    onError: (error) => pushApiResErrorToast(error, { autoDismiss: false }),
  });

  const schoolDownloadReportMutation: UseSchoolRto['schoolDownloadReportMutation'] = useMutation(downloadSchoolReport, {
    onSuccess: handleDownloadReportSuccess,
    onError: (error) => pushApiResErrorToast(error),
  });

  const schoolDownloadDeviceTableMutation: UseSchoolRto['schoolDownloadDeviceTableMutation'] = useMutation(
    DownloadDeviceTable,
    {
      onSuccess: handleDownloadReportSuccess,
      onError: (error) => pushApiResErrorToast(error),
    },
  );

  const streetLightImportMutation: UseSchoolRto['streetLightImportMutation'] = useMutation(importStreetLight, {
    onSuccess: handleMutationSuccess,
    onError: (error) => pushApiResErrorToast(error),
  });

  return {
    schoolListQuery,
    schoolProgressQuery,
    schoolDropdownOptionsQuery,
    userAssignSchoolMutation,
    schoolCreateMutation,
    schoolUpdateMutation,
    schoolDeleteMutation,
    schoolSurveyInfoUpdateMutation,
    schoolUploadImageMutation,
    schoolDeleteImageMutation,
    schoolTemplateDownloadMutation,
    schoolImportMutation,
    schoolDownloadReportMutation,
    schoolDownloadDeviceTableMutation,
    streetLightImportMutation,
  };
};
