import { neutral } from 'oring-ui/lib/styles/variables';
import { createGlobalStyle } from 'styled-components';

export const Normalize = createGlobalStyle`
  html { 
    line-height: 1.5;
  }


  // !此部分是為了修改在 contextual-menu section header 的樣式
  // 由於 fluent-ui 針對 menu item 細部樣式的調整十分困難，因此暫時只能透過此方式改變
  // 並且 menu 是使用了 portal 生成在最外層的 dom，因此必須使用 global style 才能吃到
  .ms-ContextualMenu-header span {
    font-size: 14px;
    font-weight: 600;
    color: ${neutral.primary};
  }

  .ms-ContextualMenu-item  span {
    font-size: 14px;
    color: ${neutral.primary};
  }
`;
