import styled from 'styled-components';
import { themeDark, themeDarker, support } from 'oring-ui/lib/styles/variables';
import { NavLink } from 'react-router-dom';

import { StyleMobileSidebarProps, ActiveProps } from './types';
import { IButtonStyles } from '@fluentui/react';

export const StyleMobileSidebar = styled.div<StyleMobileSidebarProps>`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${themeDark};
  height: 100vh;
  z-index: 999;
  transition: 0.3s;
  overflow: hidden;

  ${({ expand }) => `width: ${expand ? '320px' : 0}`};
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 24px;
  height: 60px;
`;

export const StyleNavWrapper = styled.div`
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden auto;
`;

export const StyleNavLink = styled(NavLink)<ActiveProps>`
  display: block;
  color: #fff;
  cursor: pointer;
  transition: 0.3s;

  &:hover,
  &.active {
    background-color: ${themeDarker};
  }

  ${({ $active }) => $active && `background-color: ${themeDarker};`}
`;

export const NavItem = styled.li<ActiveProps>`
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 16px 24px;
  color: #fff;

  i {
    font-size: 16px;
  }

  p {
    opacity: 1;
    white-space: nowrap;
    margin-left: 16px;
    position: relative;

    ${({ $active }) =>
      $active &&
      `
      &::after {
        display: block;
        content: '';
        position: absolute;
        bottom: -8px;
        left: 0;
        right: 0;
        height: 2px;
        background-color: ${support.primary};
        width: 100%;
      }
    `}
  }
`;

export const Accordion = styled.div<{ expand: boolean }>`
  transition: max-height 1s;
  overflow: hidden;
  max-height: ${({ expand }) => (expand ? '100%' : 0)};
`;

export const ExpandIconButtonStyle: IButtonStyles = {
  root: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#fff',
  },
};
