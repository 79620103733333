import React, { FC, useMemo } from 'react';
import { Redirect } from 'react-router';
import { Formik, Form, Field } from 'formik';
import { Button } from 'oring-ui/lib/components';

import { PublicLayout } from 'components/publicLayout';
import { Card, CardTitle } from 'components/styled';
import { TextField, DropdownField } from 'components/formikFields/fields';
import { roleOptions } from 'constants/options';
import { useAuth, SignInArgs } from 'hooks/auth';

import 'config/axios';

const Login: FC = () => {
  const { userSignInMutation, isLoggedIn } = useAuth();

  const handleSubmit = (values: SignInArgs) => {
    userSignInMutation.mutate(values);
  };

  const formInitialValues = useMemo<SignInArgs>(
    () => ({
      roleId: undefined || 1,
      account: '',
      password: '',
    }),
    [],
  );

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <PublicLayout>
      <Card>
        <CardTitle>設備安裝管理</CardTitle>
        <Formik initialValues={formInitialValues} onSubmit={handleSubmit}>
          {({ dirty }) => (
            <Form>
              <Field name="roleId" label="角色" required component={DropdownField} options={roleOptions} />
              <div style={{ marginBottom: '8px' }} />
              <Field name="account" label="帳號" required component={TextField} />
              <div style={{ marginBottom: '8px' }} />
              <Field name="password" label="密碼" required type="password" component={TextField} />
              <div style={{ textAlign: 'center', marginTop: '16px' }}>
                <Button
                  type="submit"
                  disabled={!dirty || userSignInMutation.isLoading}
                  primary
                  isLoading={userSignInMutation.isLoading}
                >
                  登入
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </PublicLayout>
  );
};

export default Login;
