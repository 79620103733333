import { UseMutationResult, UseQueryResult, MutationFunction } from 'react-query';

import { AxiosErrorDto, SuccessDto } from 'types/axios';

export enum RoleNumberEnum {
  ADMIN = 1,
  CONTRACTOR = 2,
  MANAGER = 3,
  WORKER = 4,
  VENDOR = 5,
}

// get user info
export type UserDto = { id: number; name: string; account: string; roleId: RoleNumberEnum };

// login
export type SignInArgs = { roleId: RoleNumberEnum; account: string; password: string };
export type SignInUserFunc = MutationFunction<SuccessDto<SignInRto>, SignInArgs>;
export type SignInRto = { accessToken: string };

// logout
export type LogoutUserFunc = MutationFunction<SuccessDto<string>>;

export type UseAuthRto = {
  userQuery: UseQueryResult<UserDto>;
  userSignInMutation: UseMutationResult<SuccessDto<SignInRto>, AxiosErrorDto, SignInArgs>;
  userLogoutMutation: UseMutationResult<SuccessDto<string>, AxiosErrorDto>;
  isLoggedIn: boolean;
};
