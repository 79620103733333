import { GatewayDropdownOptionsDto } from 'hooks/gateway';

/**
 * @param  {GatewayDropdownOptionsDto[]} options
 * @param  {number|undefined} gatewayId
 * 威力的 gateway ID-S-342GT 支持 4 port 但是 IDs-312L 沒有，因此 disabled IDs-312L 2,3,4 port
 */
export const generateGatewayPortOptions = (
  options: GatewayDropdownOptionsDto[],
  gatewayId: number | undefined | null,
) => {
  if (typeof gatewayId === 'undefined') {
    return [];
  }

  const model = options.find(({ id }) => id === gatewayId)?.model;

  return [
    { key: 4001, text: 'Port 4001' },
    { key: 4002, text: 'Port 4002', disabled: model !== 'IDS-342GT' },
    { key: 4003, text: 'Port 4003', disabled: model !== 'IDS-342GT' },
    { key: 4004, text: 'Port 4004', disabled: model !== 'IDS-342GT' },
  ];
};
