import React, { FC, Suspense, useMemo } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Spinner } from 'oring-ui/lib/components';

import { filterOutInsufficientPermissionRoutes } from 'routes/utils/filterOutInsufficientPermissionRoutes';
import { otherRoutesConfig, schoolDevicesRoutesConfig } from 'routes/config';
import PrivateLayout from 'components/privateLayout';
import { useAuth } from 'hooks/auth';
import ToggleSchoolProvider from 'context/toggleSchoolProvider';

import { findFirstRoutePathBasedOnCurrentRole, generateRoutes } from './utils';

export const URL_PREFIX = '/console';

export const PrivateRoutes: FC = () => {
  const {
    userQuery: { data },
  } = useAuth();

  const permissionAllowedSchoolDevicesRoutes = useMemo(
    () => filterOutInsufficientPermissionRoutes(schoolDevicesRoutesConfig, data?.roleId),
    [data?.roleId],
  );
  const flatSchoolDevicesRoutesConfig = useMemo(
    () => generateRoutes(permissionAllowedSchoolDevicesRoutes, data?.roleId),
    [data?.roleId],
  );
  const permissionAllowedOtherRoutes = useMemo(
    () => filterOutInsufficientPermissionRoutes(otherRoutesConfig, data?.roleId),
    [data?.roleId],
  );
  const flatOtherRoutesConfig = useMemo(
    () => generateRoutes(permissionAllowedOtherRoutes, data?.roleId),
    [data?.roleId],
  );

  return (
    <PrivateLayout
      routes={[permissionAllowedSchoolDevicesRoutes, permissionAllowedOtherRoutes]}
      pathPrefix={URL_PREFIX}
    >
      <Suspense fallback={<Spinner layout="center" />}>
        <Switch>
          {/* 其他頁面 */}
          {flatOtherRoutesConfig.routes}

          {/* 學校設備管理  */}
          <Route path={flatSchoolDevicesRoutesConfig.paths} exact>
            <ToggleSchoolProvider>
              <Switch>{flatSchoolDevicesRoutesConfig.routes}</Switch>
            </ToggleSchoolProvider>
          </Route>

          <Redirect
            to={`${URL_PREFIX}${findFirstRoutePathBasedOnCurrentRole(
              permissionAllowedSchoolDevicesRoutes,
              data?.roleId,
            )}`}
          />
        </Switch>
      </Suspense>
    </PrivateLayout>
  );
};
