import { RoleNumberEnum } from 'hooks/auth';
import { RoutesConfig } from 'routes/config';

/**
 * @param  {RoutesConfig} config
 * @param  {RoleNumberEnum} roleId
 *  此 function 會回傳第一個搜索到符合使用者權限的網址
 *  若使用者輸入了一個未知的 url 或第一次進入頁面時，則會進入此 function
 */
export const findFirstRoutePathBasedOnCurrentRole = (
  config: RoutesConfig,
  roleId: RoleNumberEnum | undefined,
): string => {
  const firstFindingRoute = config.find(({ permission }) => typeof roleId === 'number' && permission?.includes(roleId));

  if (firstFindingRoute?.component) {
    return firstFindingRoute.path;
  }

  const firstChildTabRoute = firstFindingRoute?.children?.find(({ asNavTab }) => asNavTab);

  return firstChildTabRoute ? firstChildTabRoute.path : '/';
};
