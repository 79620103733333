export const schoolProgressResponsePlaceholder = {
  data: {
    name: '',
    assetsQtys: [],
  },
  paging: {
    beforeCursor: null,
    afterCursor: null,
  },
  total: 0,
};
