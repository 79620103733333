import styled, { css } from 'styled-components';
import { themeDark, themeDarker } from 'oring-ui/lib/styles/variables';
import { NavLink } from 'react-router-dom';

import { StyleSidebarProps } from './types';

const Hide = css`
  opacity: 0;
  transition: opacity 0.1s;
  transition-delay: 0.2s;
`;

export const StyleLaptopSidebar = styled.div<StyleSidebarProps>`
  ${({ narrow }) => `
    width: ${narrow ? '72px' : '264px'};
    min-width: ${narrow ? '72px' : '264px'};
  `}

  color: #fff;
  background-color: ${themeDark};
  transition: 0.3s;
  overflow: hidden;
  position: relative;

  a {
    color: #fff;
  }
`;

export const StyleHead = styled.div<StyleSidebarProps>`
  height: 72px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #c8c6c480;
  padding: 0 24px;

  h3 {
    white-space: nowrap;
    font-size: 32px;
    margin-left: 16px;
    opacity: 1;

    ${({ narrow }) => narrow && Hide}
  }
`;

export const StyleNavWrapper = styled.nav`
  position: absolute;
  top: 72px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden auto;
`;

export const StyleNavLink = styled(NavLink)`
  display: block;
  padding: 16px 24px;
  cursor: pointer;
  transition: 0.3s;

  &:hover,
  &.active {
    background-color: ${themeDarker};
  }
`;

export const NavItem = styled.li<StyleSidebarProps>`
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  i {
    font-size: 16px;
  }

  p {
    opacity: 1;
    white-space: nowrap;
    margin-left: 16px;
    ${({ narrow }) => narrow && Hide}
  }
`;
