import styled from 'styled-components';
import { LANDSCAPE_TABLET, PORTRAIT_TABLET } from 'constants/breakPoints';

export type InfoBlockProps = {
  itemsPerRowInLaptop?: number;
  itemsPerRowInTablet?: number;
};

export const InfoTitle = styled.h3`
  font-size: 1em;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.theme.primary};
`;

export const InfoWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.light};
  padding: 1em;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const InfoBlock = styled.div<InfoBlockProps>`
  flex-basis: ${({ itemsPerRowInLaptop }) => (itemsPerRowInLaptop ? `${(1 / itemsPerRowInLaptop) * 100}%` : '33.33%')};

  @media (max-width: ${LANDSCAPE_TABLET}) {
    flex-basis: ${({ itemsPerRowInTablet }) => (itemsPerRowInTablet ? `${(1 / itemsPerRowInTablet) * 100}%` : '50%')};
    margin-bottom: 2em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: ${PORTRAIT_TABLET}) {
    flex-basis: 100%;
  }

  .title {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.neutral.primary4};
  }

  .item {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.neutral.primary};

    &:last-child {
      margin-bottom: 0;
    }

    .key {
      font-size: 14px;
      font-weight: 600;
      min-width: 120px;
    }

    .value {
      margin-right: 8px;
    }
  }
`;
