import React, { FC, Fragment, ReactNode, useState } from 'react';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { useLocation } from 'react-router-dom';
import { IconButton } from '@fluentui/react';

import { useSidebar } from 'context/sidebarsProvider';
import { SidebarProps } from 'components/sidebar';
import IconWrapper from 'components/iconWrapper';

import {
  StyleMobileSidebar,
  Header,
  StyleNavLink,
  NavItem as StyleNavItem,
  Accordion,
  StyleNavWrapper,
  ExpandIconButtonStyle,
} from './styles';
import { Obj, NavItemProps } from './types';
import { ConditionalWrapper } from 'components/conditionalWrapper';

const MobileSidebar: FC<SidebarProps> = ({ navItems }) => {
  const { mobileSidebarExpand, setMobileSidebarExpand } = useSidebar();
  const { pathname } = useLocation();
  const [subExpand, setSubExpand] = useState<Obj<boolean>>({});

  const handleSubExpand = (shouldExpand: boolean, title: string) => () => {
    setSubExpand({ [title]: shouldExpand });
  };

  return (
    <StyleMobileSidebar expand={mobileSidebarExpand}>
      <Header>
        <FontIcon
          onClick={() => setMobileSidebarExpand(false)}
          iconName="Cancel"
          style={{ color: '#fff', fontSize: '19px', cursor: 'pointer' }}
        />
      </Header>
      <StyleNavWrapper>
        {navItems.map(function m({ url, title, iconName, children, component }): ReactNode {
          const hasSubMenu = children?.some(({ asTabNav }) => asTabNav);

          return (
            <>
              <div style={{ position: 'relative' }} key={title}>
                <ConditionalWrapper
                  condition={!!component}
                  wrapper={(children) => (
                    <StyleNavLink key={url} exact to={url} onClick={() => setMobileSidebarExpand(false)}>
                      {children}
                    </StyleNavLink>
                  )}
                >
                  <NavItem title={title} iconName={iconName} isActive={pathname === url} />
                </ConditionalWrapper>
                {hasSubMenu && (
                  <IconButton
                    iconProps={{ iconName: subExpand[title] ? 'ChevronUp' : 'ChevronDown' }}
                    styles={ExpandIconButtonStyle}
                    onClick={handleSubExpand(!subExpand[title], title)}
                  />
                )}
              </div>
              {hasSubMenu && (
                <Accordion expand={subExpand[title]}>{children?.filter(({ asTabNav }) => asTabNav).map(m)}</Accordion>
              )}
            </>
          );
        })}
      </StyleNavWrapper>
    </StyleMobileSidebar>
  );
};

export default MobileSidebar;

const NavItem: FC<NavItemProps> = ({ title, iconName, isActive, ...rest }) => {
  return (
    <StyleNavItem $active={isActive} {...rest}>
      <IconWrapper width={24}>
        <FontIcon iconName={iconName} />
      </IconWrapper>
      <p>{title}</p>
    </StyleNavItem>
  );
};
